/**
 *  Sections / Section
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Section Section
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";

/** Local Dependencies */
import { CTA } from "../../components/CTA";

/** Images */
import contactBackgroundDecorationUrl from "../../images/decoration-left.svg";
import iconPhone from "../../images/icons/phone.svg";

/** Styles */
import * as styles from "./Contact.module.scss";

/** Component */
export const Contact: React.FC<
  Omit<React.ComponentProps<"div">, "children">
> = ({ className }) => (
  <div className={classNames(styles.root, className)}>
    <img
      src={contactBackgroundDecorationUrl}
      className={styles.background}
      alt="обръчи"
    />
    <div className={styles.content}>
      <p className={styles.text}>За поръчки</p>
      <p className={styles.description}>
        Поръчайте лесно и удобно на следните телефони или изпратете запитване по
        имейл.
      </p>
      <div className={styles.phones}>
        <div className={styles.phone}>
          <img src={iconPhone} alt="телефон" />
          <p className={styles.phoneText}>0887 / 27 70 70</p>
        </div>
        <div className={styles.phone}>
          <img src={iconPhone} alt="телефон" />
          <p className={styles.phoneText}>0899 / 999 222</p>
        </div>
      </div>
      <CTA href="mailto:office@primabuild.com">Изпрати запитване</CTA>
    </div>
  </div>
);
