/**
 *  Pages / About
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  About page
 *
 */

/** Dependencies */
import React from "react";
import { Helmet } from "react-helmet";

/** Local Dependencies */
import { Contact } from "../sections/Contact";
import { Head } from "../components/Head";
import { Typography } from "../components/Typography";

/** Images */
import backgroundUrl from "../images/plant-1.jpg";

/** Styles */
import * as styles from "./about.module.scss";

const AboutPage: React.FC = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Примабилд ООД - За нас</title>
      <link rel="canonical" href="https://www.primabuild.com/about" />
    </Helmet>
    <Head title="За нас" backgroundSrc={backgroundUrl}>
      <Typography
        variant="headlineMedium"
        component="h2"
        className={styles.headText}
      >
        15 години опит в бранша
      </Typography>
    </Head>
    <div className={styles.content}>
      <Typography variant="bodyMedium" className={styles.text}>
        Примабилд ООД е компания създадена през 2006 г. и се превръща в
        предвидим партньор и регионален лидер в доставката на бетон и бетонови
        смеси.
      </Typography>
      <Typography variant="bodyMedium" className={styles.text}>
        Богатият опит на бетоновия възел помага в процеса на полагането на
        проодукта и реализацията на задачите на клиентите.
      </Typography>
      <Typography variant="bodyMedium" className={styles.text}>
        Примабилд ООД се стреми към създаване на качествени продукти и
        професионализъм, които да удовлетворяват всички изисквания на нашия
        клиент. Гаранция за сигурност и спокойствие на Вашата инвестиция!
      </Typography>
    </div>
    <Contact />
  </>
);

export default AboutPage;
